<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
        {{ $t('forms.add_model', {model: $t('models.group')})}}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

         <!-- AVATAR -->
          <div
          class="text-center"
          style="width: 140px; margin: auto;">
            <b-carousel
              id="carousel-example-generic"
              controls
              :interval="0"
              v-model="avatar_slide"
            >
              <b-carousel-slide v-for="i in 18" :key="i" 
              :img-src="require('@/assets/images/avatar/groups/'+i+'.png')" />

            </b-carousel>
          </div>
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            :name="$t('fields.name')"
            rules="required|max:255"
            :custom-messages="{ required: $t('validators.required') }"
          >
            <b-form-group
              :label="$t('fields.name')"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="recordData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t('forms.add')}}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('forms.cancel')}}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { createGroup  } from '@/api/routes'
import {
  BCarousel, BCarouselSlide,
  BInputGroup, BInputGroupAppend,
  BSidebar,BMedia, BForm, BCol, BFormDatepicker, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormCheckbox
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { max } from 'vee-validate/dist/rules';
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

export default {
  components: {
    BCarousel, BCarouselSlide,
    BInputGroup, BInputGroupAppend,
    BMedia,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormCheckbox,
    BFormDatepicker, 
    BButton,
    vSelect,
    BCol,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewSidebarActive',
    event: 'update:is-add-new-sidebar-active',
  },
  props: {
    isAddNewSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  created(){
    extend('required', {
      ...required,
      message: this.$t('forms.errors.required'),
    });
    extend('max', {
      ...max,
      message: (field, { length }) => this.$t('forms.errors.max_characters', { length })
    });
  },
  data() {
    return {
      required,
      avatar_slide:1,

    }
  },
  setup(props, { emit }) {
    const blankrecordData = {
      name: '',
      id_avatar:1,
    }

    const recordData = ref(JSON.parse(JSON.stringify(blankrecordData)))
    const resetrecordData = () => {
      recordData.value = JSON.parse(JSON.stringify(blankrecordData))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetrecordData)


    return {
      recordData,

      // form validation
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  methods:{
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
    async onSubmit(){
      let data = this.recordData
      data.id_avatar = this.avatar_slide +1
      // console.log(data)
      await this.$http.post(createGroup, data).then( response => {
        // console.log(response)
        if(response.data.status == 200){
          this.makeToast('success', this.$t('Success'), this.$t('forms.save_success'));
          this.$emit('refetch-data')
          this.$emit('update:is-add-new-sidebar-active', false)
        } else {
          this.makeToast('danger', this.$t('Error'), response.data.msg);
        }
      }).catch(error => {
        // console.log("Err -> ", error);
        this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
      })
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
