<template>
  <div>
    <!-- BREADCRUMBS -->
    <custom-breadcrumb
      :pageTitle="$t('Groups')"
      :breadcrumb="breadcrumb"
    ></custom-breadcrumb>

    <add-new
      :is-add-new-sidebar-active.sync="isAddNewGroupSidebarActive"
      @refetch-data="getData"
    />
    <!-- FORM DE DREAMER -->
    <add-new-dreamer
      v-if="isAddNewDreamerSidebarActive"
      :preselected_group="preselected_group"
      :groups="own_groups"
      :nee="nee"
      :topics="topics"
      :is-add-new-dreamer-sidebar-active.sync="isAddNewDreamerSidebarActive"
      @refetch-data="getData"
    />

    <modal-personalize
      :isGroup="true"
      :selected_data="selGroupsData"
      @personalize_modal_active="personalize_modal_active = false"

    />
    <modal-content-restriction
      :isGroup="true"
      :selected_data="selGroupsData"
      @content_restriction_modal_active="content_restriction_modal_active = false"
    />

    <modal-children-passwords
      :isGroup="true"
      :selected_data="selGroupsData"
      @password_modal_active="password_modal_active = false"
    />
    <modal-massive
      from="grupos"
    ></modal-massive>

    <!-- Buttons -->
    <b-row v-if="!loading_data">
      <b-col cols="6">
        <b-button
          v-b-tooltip.hover.top="$store.getters['general/getTableView']?$t('layout.change_to_grid_view'):$t('layout.change_to_table_view')"
          variant="primary"
          type="button"
          class="mr-1 mb-1"
          @click="changeView()"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          <feather-icon
            :icon="$store.getters['general/getTableView']?'GridIcon':'ListIcon'"
            size="16"
          />
        </b-button>
        <b-form-checkbox
          v-if="!($store.getters['general/getTableView'])&&groups.length>0"
          v-model="allSelected"
          @change="manageSelectAll()"
          inline
        >
          {{ $t('table.select_all')}}
        </b-form-checkbox>
      </b-col>
      <b-col cols="6" style="text-align:right">
        
          <b-button
           v-for="button in buttonsOptions"
            :key="button.action"
            v-b-tooltip.hover.top="button.name"
            :variant="button.variant||'primary'"
            type="button"
            class="ml-1 mb-1"
            @click="handle(button.action)"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          >
          <feather-icon
            :icon="button.icon"
            size="16"
          />
          </b-button>
          
      </b-col>

    </b-row>
    <!-- TABLA -->
    <b-card v-if="$store.getters['general/getTableView']" no-body>
      <paginated-table
        ability="Groups"
        link='group'
        :data="groups"
        :canRecover="false"
        :canDownload="false"
        :loading_data="loading_data"
        :can_create_new_dreamers="can_create_new_dreamers"
        
        @add-dreamer="addDreamer" 
        @manage-selected-rows="manageSelectedRows"
        @personalize="showPersonalizeModal"
        @content-restriction="showContentRestrictionModal"
        @passwords="showPasswordsModal"
        @delete="deleteModel"
        @unlinkSharedGroup="unlinkSharedGroup"
        ></paginated-table>
        <!-- @share="$router.push({ name: 'group-edit', params: { id: group.id, tab:1 } })" -->
      </b-card>
    <!-- Groups Cards -->
    <b-row v-else class="match-height">
        <b-col cols="12" v-if="loading_data">
          <div class="text-center">
            <b-spinner variant="primary" label="Text Centered" />
          </div>
        </b-col>
        <b-col
          v-else
          v-for="group in groups"
          v-bind:key="group.id"
          lg="3"
          md="6"
        >

          <avatar-card 
            :item="group"
            :dropdown_options="dropdown_options(group)"
            context="group"
            :selected_items="selected_groups"
            @item-selected="itemSelected"

            @details="$router.push({ name: 'group-view', params: { id: group.id,  } })"
            @add-dreamer="addDreamer(group)" 
            @edit="$router.push({ name: 'group-edit', params: { id: group.id,  } })"
            @share="$router.push({ name: 'group-edit', params: { id: group.id, tab:1 } })"
            @personalize="showPersonalizeModal(group)"
            @content-restriction="showContentRestrictionModal(group)"
            @passwords="showPasswordsModal(group)"
            @import-masive="$bvModal.show('bv-modal-massive')"
            @delete="deleteModel(group.id)"
            @unlinkSharedGroup="unlinkSharedGroup(group.id)"
          />
      
        </b-col>
        
    </b-row>

  </div>
    
</template>

<script>
import { getGroup, exportGeneral, deleteSelectedGroups, deleteGroup, unlinkShared  } from '@/api/routes'

import CustomBreadcrumb from '@/views/components/breadcrumb/CustomBreadcrumb.vue'
import AvatarCard from '@/views/sl-components/AvatarCard.vue'

import AddNew from './AddNew.vue'
import AddNewDreamer from '../dreamers/AddNew.vue'
import {
  BCard, BCardText, BButton, BRow, BCol, BImg, BCardBody, BCardTitle, BCardSubTitle, BLink,
  BMedia, BMediaAside, BAvatar, BAvatarGroup, VBTooltip, BMediaBody,
  BDropdown, BDropdownItem, BDropdownDivider,
  BNav, BNavItem, BNavItemDropdown,
  BFormCheckbox,
  BFormGroup, BFormInput, BForm, BFormRadio,
  BBreadcrumb, BBreadcrumbItem, BSpinner
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ModalPersonalize from '../modals/ModalPersonalize.vue'
import ModalContentRestriction from '../modals/ModalContentRestriction.vue'
import ModalChildrenPasswords from '../modals/ModalChildrenPasswords.vue'
import ModalMassive from '../modals/ModalMassive.vue'
import PaginatedTable from '@/views/sl-components/PaginatedTable.vue'
import { decryptData } from '@/auth/utils'

export default {
  components: {
    AvatarCard,
    CustomBreadcrumb,
    AddNew, 
    AddNewDreamer,

    ModalPersonalize,
    ModalContentRestriction,
    ModalChildrenPasswords,
    ModalMassive,
    PaginatedTable,

    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BImg,
    BMedia, BMediaAside, BAvatar, BAvatarGroup, VBTooltip, BMediaBody,
    BDropdown, BDropdownItem, BDropdownDivider,
    BNav, BNavItem, BNavItemDropdown,
    BFormCheckbox,
    BFormGroup, BFormInput, BForm, BFormRadio,
    BBreadcrumb, BBreadcrumbItem, 
    BSpinner,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      loading_data:true,
      isAddNewGroupSidebarActive: false, 
      isAddNewDreamerSidebarActive: false, 

      nee:[],
      topics:[],
      groups:[],
      own_groups:[],

      group:null,
      password_modal_active: false,
      content_restriction_modal_active: false,
      personalize_modal_active: false, 

      selected_groups: [],
      allSelected: false,
      owned_dreamers:0,

      user_role:'',
    }
  },
  mounted(){
        this.getData()
      this.user_role = this.$store.getters['user/getRole'];

  },
  computed:{
    selGroupsData(){
      if((this.personalize_modal_active ||this.content_restriction_modal_active ||this.password_modal_active) && this.group){
        let data = {
          ids: [this.group.id],
          info: [{
            id: this.group.id,
            name: this.group.name,
            avatar: this.group.id_avatar || 'null',
          }]
        }
        return data
      } else {
        let g = this.selected_groups.map(x => this.groups.filter(e=> e.id == x))
        let clean_g = g.map(x=>{ return{'id': x[0].id, 'name': x[0].name, 'avatar': x[0].id==0?'null': x[0].id_avatar, 'owner': x[0].owner}})
        let data = {
          ids: this.selected_groups,
          info: clean_g
        }
        return data
      }
    },
    preselected_group(){
      let g = []
      if(this.group){
         g.push(this.group)
      } 
      return g      
    },
    buttonsOptions(){
        let opt = []
        if(this.user_role != 'teacher'){
          opt.push({
            action: 'add',
            icon: "PlusIcon",
            variant: "success",
            name: this.$t('forms.add_model', {model: this.$t('models.group')})
          }) 
        }
        // if(this.groups.length>0){
        //   opt.push({
        //       action: "exportToExcel",
        //       icon: "DownloadIcon",
        //       name: this.$t('table.export_to_excel'),
        //     }) 
        // }
        if(this.selected_groups.length > 0){
          opt.push(
            {
              action: "customizeDreamers",
              icon: "SlidersIcon",
              name: this.$t('customize.customize'),
            }, 
            {
              action: "contentRestriction",
              icon: "ClockIcon",
              name: this.$t('customize.mandatory'),
            }, 
            {
              action: "assignPasswords",
              icon: "UnlockIcon",
              name: this.$t('children_passwords.edit_children_passwords'),
            }, 
            {
              action: "deleteSelection",
              icon: "Trash2Icon",
              name: this.$t('table.delete_selection'),
              variant: "danger",
            })
        }
        return opt
    },
    breadcrumb(){
      return [
        {
          text: this.$t('Groups'),
          active: true,
        },
      ]
    },
    can_create_new_dreamers(){
      let userData = decryptData(localStorage.getItem('userData'))
      if(userData.user_max_children == 0){
        //unlimited dreamers
        return true
      } else {
        if(this.owned_dreamers < userData.user_max_children){
          return true
        }
      }
      return false
    }
  },
  methods:{
    changeView(){
      /*
       * Unselect groups when view changes
       *
       * (Si queremos que la seleccion se mantenga, solo funciona de table a grid. 
       * Si seleccionamos en grid y pasamos a tabla, la tabla no recibe los seleccionados.) 
       */
      this.$store.commit('general/setTableView');
      this.selected_groups = []
      this.allSelected = false
    },
    manageSelectedRows(selected_rows){
      let ids_only = selected_rows.map(x => x.id)
      // console.log(selected_rows, "selected_rows", ids_only)
      this.selected_groups = ids_only
    },
    showPersonalizeModal(group){
      this.personalize_modal_active = true
      // console.log(group)
      this.group = group
      this.$bvModal.show('modal-personalize')
    },
    showContentRestrictionModal(group){
      this.content_restriction_modal_active = true
      this.group = group
      this.$bvModal.show('modal-content-restriction')
    },
    showPasswordsModal(group){
      this.password_modal_active = true
      this.group = group
      this.$bvModal.show('modal-assign-pass')
    },
    dropdown_options(group){
      let options = []
      
      if(group.owner || group.id == 0){
          options.push({
          action: 'add-dreamer',
          color: null,
          title: this.$t('forms.add_model', { model: this.$t('models.dreamer') }),
          icon: "UserPlusIcon",
          disabled: !this.can_create_new_dreamers
        })
        if(group.id!=0){
          options.push({
            action: 'edit',
            color: null,
            title: this.$t('forms.edit_model', {model: this.$t('models.group')}),
            icon: "EditIcon"
          })
          options.push(
          {
            action: 'share',
            color: null,
            title: this.$t('general.share'),
            icon: "Share2Icon"
          })
        }
      }
      options.push(
        {
          action: "details",
          color: null,
          title: this.$t('table.details'),
          icon: "FileTextIcon",
        },
        {
          action: 'personalize',
          color: null,
          title: this.$t('customize.customize'),
          icon: "SettingsIcon"
        },
        {
          action: 'content-restriction',
          color: null,
          title: this.$t('customize.mandatory'),
          icon: "ClockIcon"
        },
        {
          action: 'passwords',
          color: null,
          title: this.$t('children_passwords.edit_children_passwords'),
          icon: "UnlockIcon",
        }
      )

      if(group.owner){
        if(group.id!=0){
          options.push({
            action: 'delete',
            color: 'red',
            title: this.$t('forms.delete'),
            icon: "TrashIcon"
          })
        }
      } else {
        options.push({
          action: 'unlinkSharedGroup',
          color: 'red',
          title: this.$t('dreamer.unlink_shared_group'),
          icon: "XIcon"
        })
      }
      
      return options
    },
    addDreamer(group){
      // console.log(group, "group?")
        this.isAddNewDreamerSidebarActive = true;
        this.group = group
    },
    isChecked(item){
      this.selected_groups.filter( (element, i) => {
          if(element.id === item.id){
           return true
          }
      })[0];
    },
    manageSelectAll(){
      if(this.allSelected){
        this.selected_groups = this.groups.map( i => i.id)
      } else {
        this.selected_groups = []
      }

    },
    async getData(){
        // console.log("loading")
        this.loading_data=true
        await this.$http.get(getGroup).then( response =>{
          // console.log(response, "GRoupsGeneral.vue")
          if(response.status == 200){
            this.nee = response.data.nee;
            this.topics = response.data.topics;
            this.groups = response.data.groups;
            this.owned_dreamers = response.data.owned_dreamers
            let own_g = []
            this.groups.map(g=>{
              if(g.id != 0){
                if(g.owner == true){
                  own_g.push(g)
                }
              }
            })
            this.own_groups = own_g;
            this.manageSelectAll();
            
          this.loading_data = false
          } else {
            this.loading_data = false
            this.makeToast('danger', this.$t('Error'), response.data.msg_code? this.$t('message.'+response.data.msg_code) : response.data.msg);
          }
        }).catch(error => {
          this.loading_data = false
          // console.log("Err -> ", error);
          this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
        })
    },
    itemSelected(item){
      var index = this.selected_groups.indexOf(item.id);
      if (index !== -1) {
        this.selected_groups.splice(index, 1);
      } else {
        this.selected_groups.push(item.id)
      }  

      if(this.selected_groups.length == this.groups.length){
        this.allSelected = true
      } else {
        this.allSelected = false
      }

      // console.log(this.selected_groups, "itemSelected")
  
    },
    handle(action){
      switch (action) {
        case 'add':
          this.isAddNewGroupSidebarActive = true
          break;
        case 'add-dreamer':
          this.isAddNewDreamerSidebarActive = true
          break;
        case 'customizeDreamers':
          this.$bvModal.show('modal-personalize')
          break;
        case 'contentRestriction':
          this.$bvModal.show('modal-content-restriction')
          break;
        case 'assignPasswords':
          this.$bvModal.show('modal-assign-pass')
          break;
        case 'exportToExcel':
          this.download()
          break;
        case 'deleteSelection':
          this.deleteSelection();
          break;
        default:
          break;
      }
    },
    async download(){
      let rows_ids = this.selected_groups.length > 0 ? this.selected_groups : this.groups.map(x => x.id)
      let data = {
        rows_ids : rows_ids,
        context : 'school-groups',
      }
      await this.$http.post(exportGeneral, data, {responseType:'blob'}).then( response => {
        // console.log("respuesta : ", response)
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'Groups.xlsx');
        document.body.appendChild(fileLink);
        fileLink.click();
      }).catch(error => {
        // console.log("Err -> ", error);
        this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
      })
    },
    deleteSelection(){
      let groups_data = this.selGroupsData
      let groups_array = groups_data.info.filter(x=> x.owner && x.id!=0 ).map(d=>d.id)
      // console.log(groups_array, "groups_array", groups_data)
      if(groups_array.length == 0){
        this.$bvModal
        .msgBoxOk(this.$t('table.groups_cannot_be_deleted'), {
          title: this.$t('Warning'),
          size: 'sm',
          variant: 'warning',
          okTitle: this.$t('Understood'),
          hideHeaderClose: false,
          centered: true,
        })
      } else {
        const h = this.$createElement
        const messageVNode = h('div', {}, [
          h('p',{}, [
            this.$t('table.delete_groups_selection_confirmation', {num: groups_array.length}),
            ' ',
            h('strong', this.$t('table.groups_cannot_be_deleted')),
          ]),
          h('p', {}, this.$t('table.delete_groups_selection_dreamers_will_be_moved')),
          h('p', this.$t('table.delete_groups_selection_recover')),
        ])

        this.$bvModal
          .msgBoxConfirm([messageVNode], {
            title: this.$t('table.delete_selection'),
            size: 'sm',
            okVariant: 'danger',
            okTitle: this.$t('table.delete_selection'),
            cancelTitle: this.$t('forms.cancel'),
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if(value){
              let data = {
                'groups': groups_array
              }
              this.$http.post(deleteSelectedGroups, data).then( response =>{
                if(response.data.status == 200){
                  this.getData()
                  this.makeToast('success', this.$t('Success'), this.$t('forms.delete_all.success'));
                } else {
                  this.makeToast('danger', this.$t('Error'), this.$t('forms.delete_all.error'));
                }
              }).catch(error => {
                // console.log("Err -> ", error)
              })
            }
          })
      }
    },
    deleteModel(id){
      if(id==0){
        this.$bvModal
        .msgBoxOk(this.$t('table.groups_cannot_be_deleted'), {
          title: this.$t('Warning'),
          size: 'sm',
          variant: 'warning',
          okTitle: this.$t('Understood'),
          hideHeaderClose: false,
          centered: true,
        })
      } else {
        this.$bvModal
          .msgBoxConfirm(this.$t('forms.delete_group_confirmation'), {
            title: this.$t('forms.delete'),
            size: 'sm',
            okVariant: 'danger',
            okTitle: this.$t('Yes'),
            cancelTitle: this.$t('No'),
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if(value){
              this.$http.delete(deleteGroup+'/'+id).then( response =>{
                // console.log(response, "delete:")
                if(response.data.status == 200){
                  this.getData()
                  this.makeToast('success', this.$t('Success'), this.$t('table.delete_success'));
                } else {
                    this.makeToast('danger', this.$t('Error'), this.$t('table.delete_error'));
                }
  
              }).catch(error => {
                // console.log("Err -> ", error)
                this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
              })
            }
          })
      }
    },
    unlinkSharedGroup(group_id){
      this.$bvModal
        .msgBoxConfirm(this.$t('dreamer.unlink_shared_group_confirmation'), {
          title: this.$t('dreamer.unlink_shared_group'),
          size: 'sm',
          okVariant: 'danger',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value){
            let data = {
              id: group_id,
              context: 'group'
            }
            this.$http.post(unlinkShared, data).then( response =>{
              // console.log(response, "unlink_shared_group:")
              if(response.data.status == 200){
                this.getData()
                this.makeToast('success', this.$t('Success'), this.$t('message.'+response.data.msg_code));
              } else {
                this.makeToast('danger', this.$t('Error'), this.$t('message.'+response.data.msg_code));
              }

            }).catch(error => {
              // console.log("Err -> ", error)
              this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
            })
          }
        })
    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
  }
}
</script>

<style>
#my-nav-dropdown{
    list-style-type:none;
}


</style>
